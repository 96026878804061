 @media only screen and (max-width: 768px) {
    .accordion {
       width: 100% !important;
    }
    .shipping-process{
      margin: 0!important;
      padding: 0!important;
    }
    .corosel-wrapper {
       width: 80% !important;
    }

    .App .navbar#main-nav {
       display: none !important;
    }

    .about-wrapper {
       height: 135vh !important;
    }

    .about-wrapper img {
       width: 80%;
       margin-bottom: 20px;
       padding: 0 !important;
    }

    .App {
       overflow-x: hidden;
    }

    body,
    .main-wrapper,
    .feapoints-wrapper {
       flex-direction: column;
    }

    .feapoints-wrapper {
       align-items: center;
       margin-left: 0 !important;
    }

    .box-1,
    .box-2,
    .box-3 {
       margin-bottom: 15px;
    }

    .navbar .container-fluid {
       justify-content: flex-start;
    }

    .content-wrapper,
    .book-wrapper {
       width: 100% !important;
       margin-top: 30px;
    }

    .alert-modal .modal-content {
       width: 100% !important;
       ;
    }

    .modal .alert-modal {
       width: 100% !important;
    }

    .review-wrapper .col-md-4 {
       display: flex;
       justify-content: space-between;
    }

    .modal .review-modal {
       width: 100% !important;
       left: 0 !important;
    }

    .review-modal .modal-content {
       width: 100% !important;
       right: 0 !important;
    }

    .main-component {
       height: auto !important;
    }

    .main-wrapper .book-wrapper {
       padding: 10px 10px !important
    }

    .row .form-group {
       padding: 3px !important;
    }

    .carousel-content.show-3>* {
       width: calc(100% / 1) !important;
    }

    .freight-container {
       margin-top: 40px;
    }

    .contact-form {
       margin-top: 15px;
       width: 100% !important;
    }

    .charges-tab tr td:first-child,
    .charges-tab tr td:last-child {
       padding-left: 20px !important;

    }

    #chargesForm {
       width: 90% !important;
    }

    .bread-wrapper {
       font-size: 12px;
    }

    .bread-item {
       padding: 4px 2%;
    }

    .bread-item.btn-wrap .btn-danger {
       width: 65px;
       font-size: 12px;
       text-align: center;
    }

    .radio-container {
       width: 50%;
    }

    .notehr {
       display: none
    }

    .carousel-content .item {
       width: unset !important;
    }

    .networkimages .item img {
       width: 300px !important;
       height: 200px !important;
    }

    .content-wrapper h1 {
       font-size: 19px !important;
    }

    .bullet-features li h4,
    .bullet-features li {
       font-size: 18px !important;
    }

    .bullet-features li .btn-danger {
       padding: 2px 8px;
    }

    h2,
    h3 {
       font-size: 18px !important;
    }

    h5 {
       font-size: 16px !important;
    }

    .main-content p {
       font-size: 16px !important;
    }

    .main-content {
       padding: 15px 10px !important;
       width: 100% !important;
    }

    /*  new */
    .content-wrapper{
      margin-top: 3%;
      height: calc(100vh - 80px);
    }
    .book-wrapper-child{
      background-color: #b21019a8!important;
      margin: auto;
      width: 90%;
    }


 }

 @media only screen and (min-width: 768px) {
    body {
       flex-direction: column;
    }

    .mobile-menu {
       display: none
    }
 }

 @media only screen and (min-width: 1024px) {}


 .pot {
   width: 10px;
   height: 10px;
   position: relative;
   animation: myfirst 15s 3;
   animation-direction: alternate-reverse;
   transform: rotateZ(90deg) ;
   top: 0px;
   left: 30px;
 }
 
 /* @keyframes myfirst {
   0%   {left: 0px; top: 0px; transform: rotateZ(90deg); }
   50% {left: 0px; top: 390px;transform: rotateZ(180deg) }
   100% {left: 0px; top: 400px;transform: rotateZ(180deg) }
 } */

 @keyframes myfirst {
   0%   {left: 30px; top: 0px; transform: rotateZ(90deg); }
   25%  {left: 30px; top: 400px; transform: rotateZ(0deg); }
   50%  {left: 400px; top: 400px;transform: rotateZ(0deg); }
   75%  {left: 30px; top: 400px;transform: rotateZ(90deg) }
   100% {left: 30px; top: 0px;transform: rotateZ(90deg); }
 }
 /* @keyframes myfirst {
   0%   {left: 400px; top: 0px; transform: rotateZ(90deg); }
   25%  {left: 400px; top: 400px; transform: rotateZ(0deg); }
   50%  {left: 30px; top: 400px;transform: rotateZ(0deg); }
   75%  {left: 30px; top: 400px;transform: rotateZ(90deg) }
   100% {left: 30px; top: 0px;transform: rotateZ(90deg); }
 } */