.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    width: 100%;
    height: 100%;
}
.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;
    /* hide scrollbar in IE and Edge */
    scrollbar-width: none;
    /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content>* {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.carousel-content.show-2>* {
    width: 50%;
}

.carousel-content.show-3>* {
    width: calc(100% / 3);
}

.carousel-content.show-4>* {
    width: calc(100% / 4);
}

.left-arrow,
.right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid #ddd;
}

.left-arrow {
    left: 24px;
}

.right-arrow {
    right: 24px;
}

@media (hover: none) and (pointer: coarse) {

    .left-arrow,
    .right-arrow {
        display: none;
    }
}


.corosel-wrapper{
    width: 50%;
        margin: auto;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #bf1d26;
        box-shadow: 0 1px 10px #c3c3c3;
}
.owl-item .item{
    width: 100%;
        text-align: center;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    padding: 0 !important;
    font: inherit;
    border-radius: 6px;
    height: 10px;
    width: 10px;
    border: 5px solid #f1948a!important;
    margin: 3px;
    box-shadow: 0 1px 6px #c3c3c3;
}

.owl-dot.active{
    height: 15px!important;
        width: 15px!important;
        border-radius: 10px!important;
}