.gallery-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 8px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    
    @include bp( x-small ) {
      grid-template-columns: repeat(1, 1fr);
    }	  
    @include bp( small ) {
      grid-template-columns: repeat(2, 1fr);
    }	
    @include bp( large ) {
      grid-template-columns: repeat(3, 1fr);
    }	
    img {
      width: 100%;
      border: 5px solid #fff;
    }
  }
  .gallery-container h1{
    margin-bottom: 50px;
  }
  .gallery-container{
    margin-bottom: 50px;
  }
  .gallery-grid div img{
    width: 200px;
    height: 250px;
  }
  .gallery-grid div {
    position: relative;
    cursor: pointer;
    &:before, &:after {
      transition: .3s opacity ease;
      opacity: 0;
    }
    &:after {
      content: '\02194';
      font-size: 80px;
      position: absolute;
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      color: #fff;
      left: 50%;
      top: 50%;
      display: block;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 4px;
      left: 0;
      right: 0;
      background: rgba(#222, 0.5);
      display: block;
    }
    &:hover {
      &:before, &:after {
        opacity: 1;
        transition: .3s opacity ease;
      }
    }
  }
  
  .gallerymodal {
    position: fixed;
    z-index: 999;
    width: 50%;
    max-width: 800px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: block;
    overflow: hidden;
    @include bp( x-small ) {
      width: 95%;
    }	  
    @include bp( small ) {
      width: 80%;
    }	
    @include bp( large ) {
      width: 60%;
    }	
    
  }
  .gallerymodal img {
    width: 100%;
    border: 5px solid #fff;
    height: 500px;
    width: auto;
  }
  
  .modal-overlay {
    position: fixed;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
  }
  .gallerymodal-body a {
    position: absolute;
    display: inline;
    color: black;
    text-decoration: none;
    line-height: 36px;
    font-size: 30px;
    font-weight: lighter;
    background: #fff;
    border-radius: 5px;
    height: 40px; width: 40px;
    text-align: center;
  }
  .gallerymodal-body .gallerymodal-close {
    right: 0; top: 0;
    border-radius: 0 0 0 5px;
    right: 22%;
  }
  .modal-body .modal-next,
  .modal-body .modal-prev {
    right: 0; top: calc(50% - 25px);
    border-radius: 5px 0 0 5px;
    height: 50px;
    line-height: 40px;
    font-size: 60px;
  }
  .modal-body .modal-prev {
    left: 0;
    right: auto;
    border-radius: 0 5px 5px 0;
  }
  .gallerymodal-body {
    position: relative;
  }
  .owl-carousel{
    z-index: 0;
  }
  .packing-services .carousel-content .item{
    width: unset;
    border: 0;
  }
  .packing-services .owl-dots{
    display: none;
  }
  .carousel-content .item{
    box-shadow:none;
  }