#contact-form {

    padding: 40px;
    background-color: #a52a2a8f;
    border: 1px solid black;
    border-radius: 14px;
}

#contact-form .form-control:focus,
#contact-form .form-control {
    background-color: transparent;
    color: white;
}

.contact {
    text-align: left;
    color: white;
    position: absolute;
    width: 30%;
    right: 5%;
    top: 20%;
}

.main-content {
    position: relative;
    color: white;
    text-align: left;
    padding-left: 10%;
    padding-top: 5%;
    width: 80%;
}

.carousel-content .item {
    background-color: white;
    box-shadow: 0px 0px 2px 0px #c3c3c3;
    padding: 10px !important;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 350px;
    margin: 0;
    border: 1px solid lightgray;
}

.carousel-content h4 {
    font-size: 16px;
}

.testitem {
    width: 70%;
    margin: auto;
    font-size: 20px;
    padding: 5%;
    border: 1px solid lightgray;
    border-radius: 35px;
    box-shadow: 0 1px 10px #c3c3c3;
}

.bullet-features {
    list-style: none;
}

.bullet-features li {
    font-size: 20px;
    background: url(../img/tick.png) 0 5px no-repeat;
    padding-left: 20px;
}





/* service */
.service {
    margin-top: 2rem;
    padding: 4rem;
}

@media screen and (max-width: 550px) {
    .service {
        padding: 2rem;
    }
}

@media screen and (max-width: 450px) {
    .service {
        padding: 1rem;
    }
}

.service .heading {
    text-align: center;
}

.service .heading h2 {
    color: #f1948a;
}

.service .heading p {
    font-weight: 500;
    font-size: 1rem;
}

.service .services {
    margin-top: 1rem;
    max-width: 850px;
    margin: auto;
}

.service .services ul {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto auto auto;
    grid-template-columns: auto auto auto;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

@media screen and (max-width: 720px) {
    .service .services ul {
        -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
        gap: 1rem;
    }
}

@media screen and (max-width: 450px) {
    .service .services ul {
        -ms-grid-columns: auto;
        grid-template-columns: auto;
        gap: 1rem;
    }
}

.service .services ul {
    list-style: none;
}

.service .services ul li {
    position: relative;
    text-align: center;
    cursor: pointer;
    padding-bottom: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.service .services ul li:hover {
    -webkit-box-shadow: 2px 2px 3px #dfdfdf, -2px 2px 3px #dfdfdf,
        -2px 2px 3px #ffffff, 2px -2px 3px #ffffff;
    box-shadow: 2px 2px 3px #dfdfdf, -2px 2px 3px #dfdfdf, -2px 2px 3px #ffffff,
        2px -2px 3px #ffffff;
}

.service .services ul li p {
    font-size: 0.9rem;
}

.service .services ul li img {
    position: relative;
    width: 50%;
    padding: 1rem;
    height: 100px;
}


.contactmain {
    padding-top: 10vh;
}

.contactmain h2 {
    text-align: center;
    color: #f1948a;
}

.contactmain .contact-container {
    padding: 0rem 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 50px;
}

@media screen and (max-width: 550px) {
    .contactmain .contact-container {
        padding: 2rem;
    }
}

@media screen and (max-width: 450px) {
    .contactmain .contact-container {
        padding: 1rem;
    }
}

@media screen and (max-width: 720px) {
    .contactmain .contact-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.contactmain .contact-container .contact-img,
.contactmain .contact-container .contact-form {
    width: 50%;
}

.contactmain .contact-container .contact-img img,
.contactmain .contact-container .contact-form img {
    position: relative;
    width: 100%;
}

.contactmain .contact-container .contact-form .heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 1rem 0;
    color: #f1948a;
}

.contactmain .contact-container .contact-form form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media screen and (min-width: 720px) {
    .contactmain .contact-container .contact-form form {
        padding: 0 1rem;
    }
}

.contactmain .contact-container .contact-form form input,
.contactmain .contact-container .contact-form form textarea {
    padding: 0.9rem 1rem;
    margin-bottom: 2rem;
    outline: none;
    border-radius: 4px;
    border: none;

    resize: none;
}

.contactmain .contact-container .contact-form form textarea {
    height: 7rem;
}

.contactmain .contact-container .contact-form form button {
    padding: 0.7rem 0;
    width: 170px;
    border: none;
    outline: none;
    background: #dc3545;
    color: #fefefe;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.1rem;
}

.contactmain .contact-container .contact-form form button:hover {
    background-color: #bb2d3b;
}

.AboutContent-wrapper {
    width: 50%;
    margin: auto;
    text-align: center;
    box-shadow: 0 1px 10px #c3c3c3;
    padding: 4%;
    border-radius: 38px;
    border: 1px solid #d84f57;
    margin-bottom: 50px;
}

.AboutContent-wrapper .bullet-features {
    width: 300px;
    margin: auto;
    padding: 3%;
    font-weight: 700;
}

.AboutContent-wrapper p {
    text-align: left;
}

.indexnum {
    font-size: 50px;
    margin-right: 20px;
}

.star-icon {

    height: 1.5rem;
    width: 1.5rem;
    background-image: url(https://www.expressdeliveryservices.in/wp-content/plugins/site-reviews/assets/images/star-full.svg) !important;
}

.rating-wrapper {
    display: flex;
    position: relative;
    margin-bottom: 20px;
    justify-content: center;
}

.testname {
    color: #ffb900;
    font-weight: bold;
}

.contact-info {
    height: 500px;
    background-color: #bb2d3b;
    color: white !important;
    width: 380px;
}

.contact-det-wrapper {
    text-align: left;
    font-weight: bold
}

.feapoints-wrapper {
    display: flex;
    justify-content: space-around;
    margin-left: 85px;
    color: white;
    position: relative;
}

.feapoints-wrapper div {
    width: 300px;
    text-align: left;
}

.box-1,
.box-2,
.box-3 {
    background-color: #9e040d;
    border-radius: 7px;
    padding: 10px;
    box-shadow: 0 1px 10px #a9030c;
}

.about-wrapper {
    height: 130vh;
}

.feactures-carousel {
    width: 80%;
    margin: auto;
}